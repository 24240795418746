// Example:
// <h2 class="collapse-trigger">
//   Click here to expand
// </h2>
// <div class="collapse-content">
//   Content to be expanded
// </div>

function expand() {
  const collapsible = document.getElementsByClassName("collapse-trigger");
  let i;

  if (collapsible) {
    for (i = 0; i < collapsible.length; i++) {
      collapsible[i].addEventListener("click", function () {
        const content = this.nextElementSibling;
        this.classList.toggle("expanded");
        content.classList.toggle("expanded");
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
          if (!content.classList.contains("expanded")) {
            content.style.maxHeight = null;
          }
        }
      });
    }
  }
}

expand();
