function welcomeModal() {
  const modal = document.querySelector(".welcome-modal");
  const modalClose = document.querySelector(".welcome-modal__close");
  const mainPageOverlay = document.querySelector(".main-page-overlay");

  if (modal) {
    modal.classList.add("open");
    mainPageOverlay.classList.add("open");
    if (modal.classList.contains("open")) {
      modalClose.addEventListener("click", function () {
        modal.classList.remove("open");
        mainPageOverlay.classList.remove("open");
      });
    }
  }
}

document.addEventListener(
  "DOMContentLoaded",
  function () {
    setTimeout(() => {
      welcomeModal();
    }, 1000);
  },
  false,
);
