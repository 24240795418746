function simplifiedCart() {
  const cartIcon = document.querySelector(".header__cart");
  const simplifiedCart = document.querySelector(".simplified-cart");

  if (cartIcon) {
    cartIcon.addEventListener("mouseover", function () {
      simplifiedCart.classList.add("open");
    });
    cartIcon.addEventListener("mouseout", function () {
      simplifiedCart.classList.remove("open");
    });
  }
}

simplifiedCart();
