function mobileMenu() {
  const mobileMenuIcon = document.querySelector(".header__mobile-menu-icon");
  const mobileMenuCloseIcon = document.querySelector(
    ".header__mobile-menu-close-icon",
  );
  const mobileMenu = document.querySelector(".header__mobile-menu-container");
  const mobileMenuOverlay = document.querySelector(
    ".header__mobile-menu-overlay",
  );

  if (mobileMenuIcon) {
    mobileMenuIcon.addEventListener("click", function () {
      mobileMenu.classList.add("open");
      mobileMenuOverlay.classList.add("open");
      if (mobileMenu.classList.contains("open")) {
        mobileMenuCloseIcon.addEventListener("click", function () {
          mobileMenu.classList.remove("open");
          mobileMenuOverlay.classList.remove("open");
        });
      }
    });
  }
}

mobileMenu();
