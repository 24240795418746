function accessCodeMask() {
  const accessCodeInput = document.getElementById("access-code");
  if (accessCodeInput) {
    return accessCodeInput.addEventListener("blur", function () {
      accessCodeInput.value = accessCodeInput.value.replace(/(.{3})/g, "$1-");
      if (accessCodeInput.value.slice(-1) === "-") {
        accessCodeInput.value = accessCodeInput.value.slice(0, -1);
      }
    });
  }
}
accessCodeMask();
